<template>
    <ScrollableContainer>
        <div class="category-picker bg-light text-primary">
            <button class="button button-close" @click="$emit('cancel')"></button>
            <h3>Add your favourite genres</h3>

            <Tabs buttons inline>
                <Tab
                    v-for="(category, index) in artistCategories"
                    :key="category.id + index"
                    :title="category.name"
                    :selected="index === 0"
                >
                    <div class="category-picker__list">
                        <div
                            v-for="(type, index2) in category.types"
                            :key="type.id + index2"
                            class="category-picker__list-item"
                        >
                            <CollapsiblePanel
                                :is-collapsed-initial="
                                    !selected.some(({ type: t }) => t === type.id)
                                "
                            >
                                <template #header="{ toggle, isCollapsed }">
                                    <div class="category-picker__input-row" @click="toggle">
                                        <label :for="type.id">{{ type.name }}</label>
                                        <img
                                            v-if="isCollapsed"
                                            src="@/assets/icons/chevron_down_icon.svg"
                                        />
                                        <img v-else src="@/assets/icons/chevron_up_icon.svg" />
                                    </div>
                                </template>
                                <template #content>
                                    <div class="category-picker__list">
                                        <!-- <h4 class="category-picker__list-title font-weight-medium">
                                            Choose a genre
                                        </h4> -->
                                        <div
                                            v-for="genre in type.genres"
                                            :key="type.id + genre.id"
                                            class="category-picker__list-item"
                                        >
                                            <div class="category-picker__input-row">
                                                <label :for="type.id + genre.id">{{
                                                    genre.name
                                                }}</label>
                                                <input
                                                    :id="type.id + genre.id"
                                                    v-model="selected"
                                                    type="checkbox"
                                                    :value="{
                                                        category: category.id,
                                                        type: type.id,
                                                        value: genre.value
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </CollapsiblePanel>
                        </div>
                    </div>
                </Tab>
            </Tabs>
            <JumboButton v-show="changed" label="Save" type="button" @click="confirm" />
        </div>
    </ScrollableContainer>
</template>

<script>
import Tabs from '@/components/layout/Tabs'
import Tab from '@/components/layout/Tab'
import CollapsiblePanel from '@/components/CollapsiblePanel.vue'
import { ARTIST_CATEGORIES } from '@/graphql/queries/categories.js'
export default {
    name: 'CategoryPicker',
    components: {
        Tabs,
        Tab,
        CollapsiblePanel
    },
    apollo: {
        artistCategories: {
            query: ARTIST_CATEGORIES
        }
    },
    emits: ['cancel', 'input'],
    data() {
        return {
            selected: this.$attrs.value,
            artistCategories: []
        }
    },
    computed: {
        sorted() {
            return Object.values(this.selected).sort(function(a, b) {
                let c = a.category.localeCompare(b.category)
                let d = a.type.localeCompare(b.type)

                if (c === 0) {
                    if (d === 0) {
                        return a.value.localeCompare(b.value)
                    }
                    return d
                } else {
                    return c
                }
            })
        },
        changed() {
            return JSON.stringify(this.sort(this.$attrs.value)) !== JSON.stringify(this.sorted)
            // return true
        }
    },
    methods: {
        confirm() {
            this.$emit('input', this.selected)
            this.$emit('cancel')
        },
        sort(items) {
            return Object.values(items).sort(function(a, b) {
                let c = a.category.localeCompare(b.category)
                let d = a.type.localeCompare(b.type)

                if (c === 0) {
                    if (d === 0) {
                        return a.value.localeCompare(b.value)
                    }
                    return d
                } else {
                    return c
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.category-picker {
    > :first-child {
        justify-self: end;
    }

    min-height: 100vh;
    width: 100vw;
    display: grid;
    align-content: start;
    padding: 1rem;
    padding-top: 2rem;
    justify-items: start;
    padding-bottom: 110px;

    &__list {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: grid;
        row-gap: 0.5rem;
        width: 100%;
    }
    &__list-title {
        text-align: left;
    }

    &__input-row {
        display: flex;
        justify-content: space-between;
        label {
            align-self: center;
        }

        input[type='checkbox'] {
            height: 29px;
            width: 29px;
            margin: 0;
            appearance: none;
            padding: 0;
            border-radius: 2px;
            border: 0;
            background: white;
            box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.5);
            outline: 0;
        }

        input[type='checkbox']:checked {
            background: url('~@/assets/checkbox_checked.svg');
            box-shadow: 0 0 0 0 white;
        }
    }
    &__list-item {
        width: 100%;
        display: grid;
        row-gap: 0.5rem;
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .category-picker {
        max-width: 400px;
        height: 90%;
        margin: auto;
    }
}
</style>
