<template>
    <div
        :style="{
            display: 'grid',
            justifyItems: 'center',
            rowGap: '1rem'
        }"
    >
        <p>Add venue category</p>

        <div v-for="(types, category, index) in categoryDto" :key="category + index">
            <h2>{{ category }}</h2>
            <p v-for="(type, index2) in types" :key="type + index2" class="capitalize">
                {{ type.toLowerCase() }}
            </p>
        </div>
        <Button
            label="Add category"
            size="small"
            square
            type="button"
            @click="showCategoryPickerModal = true"
        />
        <Modal v-if="showCategoryPickerModal" full-screen>
            <VenueCategoryPicker v-model="selected" @cancel="showCategoryPickerModal = false" />
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/modals/Modal'
import VenueCategoryPicker from '@/components/VenueCategoryPicker'
import {
    toVenueCategoryDto as toDto,
    toVenueCategoryViewModel as toViewModel
} from '@/mappers/category'

export default {
    components: {
        Modal,
        VenueCategoryPicker
    },
    data() {
        return {
            showCategoryPickerModal: false,
            selected: toViewModel(this.$attrs.value)
        }
    },
    watch: {
        selected() {
            this.$emit('input', this.categoryDto)
        }
    },
    computed: {
        categoryDto() {
            return toDto(this.selected)
        },
        categoryVm() {
            return toViewModel(this.categories)
        }
    }
}
</script>

<style></style>
