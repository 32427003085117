<template>
    <div>
        <div
            v-for="(categoryTypes, categoryKey, categoryIndex) in categories"
            :key="categoryKey + categoryIndex"
        >
            <h2 class="capitalize">{{ categoryKey }}</h2>
            <div
                v-for="(genres, categoryTypeKey, typeIndex) in categoryTypes"
                :key="categoryTypeKey + typeIndex"
            >
                <h3 class="capitalize">
                    {{ categoryTypeKey }}
                </h3>
                <p v-for="genre in genres" :key="genre" class="capitalize">
                    {{ genre.toLowerCase() }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * category: Music, Dance, Theatre
 *
 * type: Music { DJ, BAND, SINGER, ORCHESTRA }
 *
 * genre: DJ { dance, club, reggea, etc...}
 */
export default {
    name: 'CategoryViewer',
    props: {
        categories: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
</style>