<template>
    <div class="tabs" v-bind="$attrs">
        <ul class="tabs__list" :class="{ 'bg-white': bgWhite }">
            <li
                v-for="tab in tabs"
                :key="tab.title"
                :class="{ 'tabs__list--inline': !inline }"
            >
                <div
                    v-if="!buttons"
                    class="tabs__tab"
                    :class="{ 'tabs__tab--active': tab.isActive }"
                    @click="selectTab(tab)"
                >
                    {{ tab.title }}
                </div>
                <button
                    v-else
                    class="tabs__button tabs__button--small"
                    :class="[
                        tab.isActive
                            ? 'tabs__button--primary'
                            : 'tabs__button--white',
                    ]"
                    type="button"
                    @click="selectTab(tab)"
                >
                    {{ tab.title }}
                </button>
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        buttons: Boolean,
        unselectedWhiteBackground: Boolean,
        inline: {
            type: Boolean,
            default: false,
        },
        bgWhite: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabs: [],
        }
    },
    created() {
        this.tabs = this.$children
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach((tab) => {
                tab.isActive = tab.title === selectedTab.title
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.tabs {
    // height: 100%;
    width: 100%;
}

ul,
li {
    padding: 0;
    cursor: pointer;
}
.tabs__list {
    list-style-type: none;
    margin: 0;

    display: flex;
    overflow-x: auto;

    li.tabs__list--inline {
        flex: 1;
        text-align: center;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }

    li + li {
        padding-left: 0.5rem;
    }

    > :last-child {
        padding-right: 0;
    }
}

.tabs__tab {
    padding: 1rem;
    font-weight: 500;
    flex: 1;
}

.tabs__tab--active {
    border-bottom: 2px solid $primary;
}

.tabs__content {
    flex: 1;
}

.tabs__button {
    border: none;
    border-radius: 3rem;
    cursor: pointer;
    outline: 0;
    line-height: 0.9rem;
}

.tabs__button--small {
    font-size: 14px;
    font-weight: 500;
    padding: 0.5rem 1rem;
}

.tabs__button--primary {
    color: $beige;
    background-color: $primary;
    border: 1px solid $primary;
}

.tabs__button--secondary {
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
}

.tabs__button--white {
    color: $primary;
    background-color: white;
    border: 1px solid white;
}
</style>
