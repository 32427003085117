<template>
    <keep-alive>
        <div v-if="isActive" v-bind="$attrs">
            <slot></slot>
        </div>
    </keep-alive>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        title: {
            required: true,
            type: String,
        },
        selected: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            isActive: false,
        }
    },
    mounted() {
        this.isActive = this.selected
    },
}
</script>
