<template>
    <Splash v-if="isProfileLoading()" />
    <ScrollableContainer v-else>
        <div class="edit-profile bg-light text-primary">
            <div class="edit-profile__content">
                <header class="edit-profile__header">
                    <img class="logo" src="@/assets/logo_primary.svg" />
                    <button
                        class="button button-close"
                        @click="$router.replace('/profile/me')"
                    ></button>
                </header>
                <h2 class="font-weight-medium">
                    {{ `Your ${profileType} profile` }}
                </h2>
                <!-- <FormState
                :initial-state="initialState"
                :current-state="currentState"
                v-slot="{ isChanged }"
                @submit="checkForm"
            >
                 -->
                <ValidationObserver v-slot="{ invalid }">
                    <FormState
                        v-slot="{ isChanged }"
                        :initial-state="initialState"
                        :current-state="currentState"
                        @submit="handleSubmit"
                    >
                        <fieldset class="gb-form__fieldset">
                            <div class="edit-profile__profile-photo">
                                <ImageUploader v-model="currentState.profileImageFile" profile>
                                    <template #temp>
                                        <img
                                            :src="profileImageUrl"
                                            class="gb-image--fit-container"
                                        />
                                    </template>
                                </ImageUploader>
                            </div>
                        </fieldset>

                        <fieldset class="gb-form__fieldset">
                            <div class="edit-profile__cover-photo">
                                <ImageUploader v-model="currentState.coverImageFile">
                                    <template #temp>
                                        <img :src="coverImageUrl" class="gb-image--fit-container" />
                                    </template>
                                </ImageUploader>
                            </div>
                        </fieldset>
                        <fieldset class="gb-form__fieldset">
                            <label for="name">Name</label>

                            <BaseInput
                                id="name"
                                v-model="currentState.name"
                                type="text"
                                placeholder="Your name"
                                text-align="center"
                                required
                            />
                        </fieldset>
                        <fieldset class="gb-form__fieldset">
                            <label for="biography">Biography</label>
                            <Textarea
                                id="biography"
                                v-model="currentState.description"
                                placeholder="write something about you..."
                            ></Textarea>
                        </fieldset>
                        <fieldset
                            v-if="isVenueProfile"
                            class="gb-form__fieldset gb-form__fieldset--centered"
                        >
                            <ValidationProvider v-slot="{ errors }" rules="category">
                                <VenueCategorySelect
                                    v-model="currentState.categories"
                                    :is-invalid="!!errors[0]"
                                />
                            </ValidationProvider>
                        </fieldset>
                        <fieldset v-else class="gb-form__fieldset gb-form__fieldset--centered">
                            <ValidationProvider v-slot="{ errors }" rules="category">
                                <CategorySelect
                                    v-model="currentState.categories"
                                    :is-invalid="!!errors[0]"
                                />
                            </ValidationProvider>
                        </fieldset>
                        <fieldset v-if="isVenueProfile" class="gb-form__fieldset">
                            <label for="address">Address*</label>
                            <ValidationProvider
                                v-slot="{ errors: [e] }"
                                rules="address"
                                name="address"
                            >
                                <AddressLocationSearch
                                    id="address"
                                    placeholder="Street address and number"
                                    :initial-val="currentState.street || ''"
                                    @input="addressSearchData = $event"
                                />
                                {{ e }}
                            </ValidationProvider>
                        </fieldset>
                        <fieldset v-if="isArtistProfile" class="gb-form__fieldset">
                            <label for="location">Location</label>
                            <ValidationProvider rules="city">
                                <CitySearch
                                    id="location"
                                    placeholder="Primary work location"
                                    :initial-val="initialState.worklocation"
                                    @input="citySearchData = $event"
                                />
                            </ValidationProvider>
                        </fieldset>
                        <fieldset
                            v-if="isArtistProfile || isVenueProfile"
                            class="gb-form__fieldset gb-form__fieldset--col-2"
                        >
                            <legend class="gb-form--span-row">
                                Average price range for rental a day*
                            </legend>

                            <BaseInput
                                v-model.number="currentState.priceMin"
                                type="number"
                                pattern="\d+"
                                placeholder="from"
                                text-align="left"
                                required
                            />

                            <BaseInput
                                v-model.number="currentState.priceMax"
                                type="number"
                                pattern="\d+"
                                text-align="left"
                                placeholder="to"
                                required
                            />
                        </fieldset>

                        <fieldset
                            v-if="isArtistProfile || isVenueProfile"
                            class="gb-form__fieldset gb-form__fieldset--col-2"
                        >
                            <legend class="gb-form--span-row">
                                Average price range for rental a day*
                                <small>(weekends)</small>
                            </legend>

                            <BaseInput
                                v-model.number="currentState.priceMinWeekend"
                                pattern="\d+"
                                type="number"
                                text-align="left"
                                placeholder="from"
                                required
                            />

                            <BaseInput
                                v-model.number="currentState.priceMaxWeekend"
                                pattern="\d+"
                                type="number"
                                text-align="left"
                                placeholder="to"
                                required
                            />
                        </fieldset>
                        <fieldset v-if="isVenueProfile" class="gb-form__fieldset">
                            <label for="capacity">Capacity*</label>

                            <BaseInput
                                id="capacity"
                                v-model.number="currentState.capacity"
                                type="number"
                                placeholder="maximum capacity"
                                text-align="center"
                                required
                            />
                        </fieldset>
                        <fieldset
                            v-if="isArtistProfile || isVenueProfile"
                            class="gb-form__fieldset"
                        >
                            <legend>Contact information</legend>
                            <BaseInput
                                v-model="currentState.email"
                                text-align="center"
                                placeholder="email"
                                type="email"
                            />
                            <BaseInput
                                v-model="currentState.phone"
                                text-align="center"
                                type="tel"
                                pattern="\d{8}"
                                placeholder="phone number"
                            />
                            <BaseInput
                                v-model="currentState.web"
                                text-align="center"
                                placeholder="http://yourwebsite.com"
                            />
                        </fieldset>
                        <JumboButton
                            v-show="isChanged && !invalid"
                            label="Save changes"
                            type="submit"
                        />
                    </FormState>
                </ValidationObserver>
            </div>
        </div>
    </ScrollableContainer>
</template>

<script>
import AddressLocationSearch from '@/components/auto-suggest/AddressLocationSearch'
import CitySearch from '@/components/auto-suggest/CitySearch'
import ImageUploader from '@/components/ImageUploader'
import FormState from './FormState'
import CategorySelect from './CategorySelect'
import VenueCategorySelect from './VenueCategorySelect'
import Textarea from '@/components/Textarea'
import logger from '@/utils/logger.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, numeric } from 'vee-validate/dist/rules'
extend('required', { ...required, message: `{_field_} is required` })
extend('numeric', numeric)
extend('category', {
    validate(value) {
        return {
            required: true,
            valid: Object.keys(value).length !== 0
        }
    },
    computesRequired: true
})
extend('city', {
    message: 'Location invalid',
    validate(value) {
        return {
            required: true,
            valid: value !== null
        }
    },
    computesRequired: true
})
extend('address', {
    message: 'Invalid address',
    validate(value) {
        return {
            required: true,
            valid: value !== null
        }
    },
    computesRequired: true
})
export default {
    name: 'EditProfile',
    components: {
        CitySearch,
        AddressLocationSearch,
        CategorySelect,
        FormState,
        ImageUploader,
        Textarea,
        VenueCategorySelect,
        ValidationProvider,
        ValidationObserver
    },
    inject: [
        'getMyCurrentProfile',
        'getCurrentProfileType',
        'isProfileLoading',
        'uploadImage',
        'updateProfile'
    ],
    data() {
        return {
            currentState: {},
            citySearchData: null,
            addressSearchData: null,
            showCategoryPickerModal: false
        }
    },
    computed: {
        profile() {
            return this.getMyCurrentProfile()
        },
        profileType() {
            return this.profile.__typename.toLowerCase()
        },
        profileImageUrl() {
            return this.profile.avatar
        },
        coverImageUrl() {
            return this.profile.coverUrl
        },
        profileMediaId() {
            return this.profile.media.id
        },
        isArtistProfile() {
            return this.getCurrentProfileType() === 'artist'
        },
        isVenueProfile() {
            return this.getCurrentProfileType() === 'venue'
        },
        initialState() {
            if (!this.profile) {
                return {}
            }
            const {
                name,
                description,
                categories,
                priceMin,
                priceMax,
                priceMinWeekend,
                priceMaxWeekend,
                worklocation,
                // eslint-disable-next-line no-unused-vars
                location,
                capacity,
                contact = {},
                address = {}
            } = this.profile

            return {
                name,
                description,
                categories: categories || {},
                priceMin,
                priceMax,
                priceMinWeekend,
                priceMaxWeekend,
                // phone,
                // email,
                // web,
                ...contact,
                ...address,
                worklocation,
                capacity,
                //Do not add location here
                profileImageFile: null,
                coverImageFile: null
            }
        },
        payload() {
            const {
                // eslint-disable-next-line no-unused-vars
                __typename,
                // eslint-disable-next-line no-unused-vars
                coverImageFile,
                // eslint-disable-next-line no-unused-vars
                profileImageFile,
                phone,
                email,
                web,
                street,
                zip,
                city,
                // eslint-disable-next-line no-unused-vars
                place,
                ...pl
            } = this.currentState

            if (!this.isArtistProfile && !this.isVenueProfile) {
                return pl
            }

            logger.log({
                ...pl,
                contact: {
                    phone,
                    email,
                    web
                },
                address: {
                    street,
                    zip,
                    city
                }
            })
            return {
                ...pl,
                contact: {
                    phone,
                    email,
                    web
                },
                address: {
                    street,
                    zip,
                    city
                }
            }
        }
    },
    watch: {
        initialState: {
            handler(val) {
                this.currentState = JSON.parse(JSON.stringify(val))
            },
            immediate: true
        },
        citySearchData(val) {
            if (!val) {
                this.currentState = {
                    ...this.currentState,
                    worklocation: null,
                    location: null
                }
            } else {
                const { city, lat, long } = val
                this.currentState = {
                    ...this.currentState,
                    worklocation: city,
                    location: {
                        lat,
                        long
                    }
                }
            }
        },
        addressSearchData(val) {
            if (!val) {
                this.currentState = {
                    ...this.currentState,
                    location: null,
                    street: null,
                    city: null,
                    zip: null
                }
            } else {
                const {
                    name,
                    postal,
                    zip,
                    geo: { lat, lng }
                } = val
                this.currentState = {
                    ...this.currentState,
                    location: {
                        lat: lat,
                        long: lng
                    },
                    street: name,
                    city: postal,
                    zip
                }
            }
        }
    },
    methods: {
        async checkForm() {
            const { categories } = this.currentState

            if (!Object.keys(categories).length) {
                alert('Please select categories')
                return false
            }
            await this.handleSubmit()
        },
        async handleSubmit() {
            logger.log('Saving changes...')
            const imageFiles = [
                {
                    type: 'profile',
                    file: this.currentState.profileImageFile
                },
                {
                    type: 'cover',
                    file: this.currentState.coverImageFile
                }
            ].filter(({ file }) => file)
            this.currentState.profileImageFile = null
            this.currentState.coverImageFile = null

            try {
                await Promise.all([
                    ...imageFiles.map(({ type, file }) =>
                        this.uploadImage(this.profileMediaId, type, file)
                    ),
                    this.updateProfile(this.payload)
                ])
            } catch (error) {
                console.log('Error when editing profile')
                console.log({ error })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';
.text--red {
    color: red;
}

.edit-profile {
    text-align: center;
    padding-bottom: 110px;
    border-radius: 5px;
    &__header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: 3rem 1rem;

        .logo {
            width: 4rem;
        }
        :nth-child(1) {
            grid-column-start: 2;
        }
        :nth-child(2) {
            justify-self: end;
        }
    }

    &__profile-photo {
        width: 120px;
        height: 120px;
        margin: auto;
        border-radius: 50%;
        background: #fff;
        row-gap: 20px;
        overflow: hidden;
    }
    &__cover-photo {
        width: 100%;
        height: 160px;
        background: #fff;
        overflow: hidden;
    }
}

input[type='tel']:placeholder-shown {
    letter-spacing: 1px;
}
@media only screen and (min-width: 768px) {
    .edit-profile__content {
        max-width: 768px;
        margin: auto;
    }
}
</style>
