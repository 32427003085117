<template>
    <div
        id="category-select"
        :style="{
            display: 'grid',
            justifyItems: 'center',
            rowGap: '1rem'
        }"
    >
        <p>
            {{ 'Add your favourite genres!' }}
        </p>

        <CategoryViewer v-if="Object.keys(categoryDto).length" :categories="categoryDto" />
        <Button
            label="Add category"
            size="small"
            square
            type="button"
            @click="showCategoryPickerModal = true"
        />
        <small v-if="isInvalid" class="text--red">Please select at least one category</small>
        <Modal v-if="showCategoryPickerModal" full-screen>
            <CategoryPicker v-model="selected" @cancel="showCategoryPickerModal = false" />
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/modals/Modal'
import CategoryPicker from '@/components/CategoryPicker'
import CategoryViewer from '@/components/CategoryViewer'
import { toDto, toViewModel } from '@/mappers/category'

export default {
    components: {
        Modal,
        CategoryPicker,
        CategoryViewer
    },
    props: {
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showCategoryPickerModal: false,
            selected: toViewModel(this.$attrs.value)
        }
    },
    watch: {
        selected() {
            this.$emit('input', this.categoryDto)
        }
    },
    computed: {
        categoryDto() {
            return toDto(this.selected)
        }
    }
}
</script>
