var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollableContainer',[_c('div',{staticClass:"category-picker bg-light text-primary"},[_c('button',{staticClass:"button button-close",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('h3',[_vm._v("Add your favourite genres")]),_c('Tabs',{attrs:{"buttons":"","inline":""}},_vm._l((_vm.artistCategories),function(category,index){return _c('Tab',{key:category.id + index,attrs:{"title":category.name,"selected":index === 0}},[_c('div',{staticClass:"category-picker__list"},_vm._l((category.types),function(type,index2){return _c('div',{key:type.id + index2,staticClass:"category-picker__list-item"},[_c('CollapsiblePanel',{attrs:{"is-collapsed-initial":!_vm.selected.some(function (ref) {
                                                var t = ref.type;

                                                return t === type.id;
})},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var toggle = ref.toggle;
var isCollapsed = ref.isCollapsed;
return [_c('div',{staticClass:"category-picker__input-row",on:{"click":toggle}},[_c('label',{attrs:{"for":type.id}},[_vm._v(_vm._s(type.name))]),(isCollapsed)?_c('img',{attrs:{"src":require("@/assets/icons/chevron_down_icon.svg")}}):_c('img',{attrs:{"src":require("@/assets/icons/chevron_up_icon.svg")}})])]}},{key:"content",fn:function(){return [_c('div',{staticClass:"category-picker__list"},_vm._l((type.genres),function(genre){return _c('div',{key:type.id + genre.id,staticClass:"category-picker__list-item"},[_c('div',{staticClass:"category-picker__input-row"},[_c('label',{attrs:{"for":type.id + genre.id}},[_vm._v(_vm._s(genre.name))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"id":type.id + genre.id,"type":"checkbox"},domProps:{"value":{
                                                    category: category.id,
                                                    type: type.id,
                                                    value: genre.value
                                                },"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,{
                                                    category: category.id,
                                                    type: type.id,
                                                    value: genre.value
                                                })>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v={
                                                    category: category.id,
                                                    type: type.id,
                                                    value: genre.value
                                                },$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})])])}),0)]},proxy:true}],null,true)})],1)}),0)])}),1),_c('JumboButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.changed),expression:"changed"}],attrs:{"label":"Save","type":"button"},on:{"click":_vm.confirm}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }